<template>
  <div>
    <h3>Create Audience</h3>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :md="19"
      >
        <audience-creation-table
          ref="geoTable"
          @selection-change="selectionChange"
        />
      </el-col>
      <el-col
        :xs="24"
        :md="5"
      >
        <audience-creation-form
          :selections="geoframes"
          @reset="handleReset"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AudienceCreationForm from '../../audience/AudienceCreationForm';
import AudienceCreationTable from '../../audience/AudienceCreationTable';

export default {
  components: { AudienceCreationForm, AudienceCreationTable },
  data() {
    return {
      geoframes: [],
    };
  },
  methods: {
    handleReset() {
      this.$refs.geoTable.clearSelection();
    },
    selectionChange(selections) {
      this.geoframes = selections;
    },
  },
};
</script>
