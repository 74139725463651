<template>
  <div>
    <h3>Create Predicted Movers Audience</h3>

    <predicted-movers-form />
  </div>
</template>

<script>
import PredictedMoversForm from '../../audience/PredictedMoversForm';

export default {
  components: { PredictedMoversForm },
  data() {
    return {
      zip: [],
    };
  },
  methods: {
    handleReset() {
      this.$refs.geoTable.clearSelection();
    },
    selectionChange(selections) {
      this.geoframes = selections;
    },
  },
};
</script>

<style lang="scss" scoped>
.applied {
  margin-bottom: 1em;
}
.dialog-footer .left {
  float: left;
}
label span.count {
  float: right !important;
}
p.small {
  font-size: 80% !important;
}
:deep( .tags),
.el-collapse,
.form-control {
  margin-top: 1em;
}
.el-tag {
  font-size: 0.75em;
  display: block;
  width: max-content;
  margin-left: 0;
  margin-bottom: 0.25em;
  cursor: pointer;
}
.el-input {
  display: inline-block;
  max-width: 60%;
}
.el-switch {
  margin-left: 1em;
}
.el-button {
  margin-top: 1em;
  &.inline {
    display: inline-block;
  }
}
.el-table .cell {
  font-size: 0.8em;
}

.help {
  display: inline-block;
  margin: 10px;
}

.pagination {
  clear: both;
  float: right;
  &:first-child {
    margin-top: 0.7em;
    margin-bottom: 1em;
  }
  &:last-child {
    margin-top: 1em;
  }
}

.selections {
  display: inline-block;
  margin-left: 20px;
}
</style>
