<template>
  <div>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :md="5"
      >
        <form>
          <div class="form-control">
            <label>Audience Name</label>
            <el-input v-model="title" />
          </div>
          <div class="form-control">
            <dedupe-control v-model="dedupe" />
          </div>

          <div class="form-control">
            <label>Organization</label>
            <org-picker :organization.sync="organization" />
          </div>

          <tag-picker :tags.sync="tags" />

          <div class="form-control">
            <label>Target Month</label>
            <el-date-picker
              v-model="month"
              type="month"
              placeholder="Pick a month"
              value-format="yyyy-MM"
              :picker-options="pickerOptions"
            />
            <div class="form-control">
              <el-button
                :disabled="!formReady"
                :loading="busy"
                type="primary"
                @click="createAudience"
              >
                {{
                  mode === 'normal' ? 'Save Audience' : 'Save as New Audience'
                }}
              </el-button>
            </div>

            <div class="form-control">
              <el-button
                :disabled="busy"
                @click="resetForm"
              >
                Reset
              </el-button>
            </div>
          </div>
        </form>
      </el-col>
      <el-col
        :xs="24"
        :md="5"
      >
        <form>
          <div class="form-control">
            <label>Enter a list of 5-digit zipcodes</label>
            <el-input
              v-model="zipcodes"
              placeholder="01083, 11010, 90210"
              type="textarea"
              :rows="10"
              :disabled="busy"
            />
            <p>
              Zipcodes may be entered one per line or comma separated. Each
              contiguous block of 5 digits will be treated as a distinct zipcode
              entry.
            </p>
            <p
              v-if="exceededZipcodeLimit"
              style="color: red"
            >
              Zipcode limit of {{ limit }} reached.
            </p>
          </div>
        </form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TagPicker from '../global/TagPicker.vue';
import OrgPicker from '../global/OrgPicker.vue';
import { audience } from '@/adonis-api';
import DedupeControl from '../global/DedupeControl.vue';

export default {
  name: 'PredictedMoversCreationForm',
  components: {
    DedupeControl,
    OrgPicker,
    TagPicker,
  },
  props: {
    mode: {
      validator(value) {
        return !value || ['normal', 'split'].includes(value);
      },
      default: 'normal',
    },
    selections: { type: Array, default: () => [] },
  },

  data() {
    return {
      busy: false,
      dedupe: this.$store.getters['user/autoDedupe'],
      organization: {},
      showDialog: '',
      title: null,
      tags: [],
      month: null,
      zipcodes: null,
      zipcodes_array: [],
      limit: this.$store.getters['settings/predictedMoversZipcodeLimit'],
      pickerOptions: {
        disabledDate(time) {
          const date = new Date(time);
          const currentDate = new Date();
          const startDate = new Date();
          const endDate = new Date();

          startDate.setMonth(currentDate.getMonth() - 3);
          startDate.setDate(1);
          endDate.setMonth(currentDate.getMonth() + 8);
          endDate.setDate(31);

          return date < startDate || date > endDate;
        },
        shortcuts: [
          {
            text: 'Last month',
            onClick(picker) {
              const date = new Date();
              date.setMonth(date.getMonth() - 1);
              picker.$emit('pick', date);
            },
          },
          {
            text: 'Current month',
            onClick(picker) {
              const date = new Date();
              picker.$emit('pick', date);
            },
          },
        ],
      },
    };
  },

  computed: {
    formReady() {
      if (!this.title || !this.zipcodes || !this.month) {
        return false;
      }

      return true;
    },
    exceededZipcodeLimit() {
      if (!this.zipcodes) {
        return false;
      }
      return (
        this.zipcodes.split(',').length >
        this.$store.getters['settings/predictedMoversZipcodeLimit']
      );
    },
  },

  watch: {
    zipcodes(val) {
      if (val) {
        const zips = val.match(/\d{5}/g);
        if (Array.isArray(zips)) {
          this.zipcodes_array = zips;
          if (this.zipcodes_array.length > this.limit) {
            this.zipcodes_array = this.zipcodes_array.slice(0, this.limit);
            this.$notify.warning(
              `You have exceeded the maximum allowed ${this.limit.toLocaleString()} Zip Codes. Excess items will be omitted.`,
            );
          }
        }
      }
    },
  },

  methods: {

    async createAudience() {
      this.busy = true;
      const params = {
        name: this.title.trim(),
        type: 'PREDICTED_MOVERS',
        organization_id: this.organization.id,
        tags: this.tags,
        zipcodes: this.zipcodes_array,
        auto_dedupe: this.dedupe,
        target_date: this.month,
      };

      try {
        const response = await audience.createPredictedMovers(params);

        window.mixpanel.track('Create Predicted Movers Audience', {
          ID: response.data.id,
          Name: response.data.name,
          Organization: this.organization.name,
        });
        this.$notify.success({
          message: 'Your audience has been saved.',
        });

        this.resetForm();
        this.$store.dispatch('mqtt/addRawJob', response.data.job);

        this.$router.push('/audiences/library/list');
      } catch (e) {
        this.$notify.error({
          message:
            'An error occurred while saving the audience. This issue has been reported.',
        });
        this.$reportError(e, {
          componentName: this.$options.name,
          params,
        });
      } finally {
        this.busy = false;
      }
    },

    resetForm() {
      this.organization = this.$store.state.auth.orgDetails;
      this.title = null;
      this.tags = [];
      this.month = null;
      this.zipcodes = null;
      this.$emit('reset');
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  margin-top: 4rem;
}

.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  .el-input,
  .el-select,
  .el-button {
    width: 100%;
  }
  .el-tag {
    background: #ddd;
    color: #333;
    margin: 0 5px 5px 0;
  }
}

.alert-count {
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  color: #ccc;
  border-color: #ddd;
  background-color: #eee;
  .count {
    margin: 0.5rem 0 0;
    font-size: 2rem;
    font-weight: 800;
    color: #bbb;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
  }
}
</style>
