<template>
  <div>
    <form>
      <div class="form-control">
        <label>Audience Name</label>
        <el-input v-model="title" />
      </div>
      <div class="form-control">
        <dedupe-control v-model="dedupe" />
      </div>

      <div class="form-control">
        <label>Organization</label>
        <org-picker
          :historical-only="historicalSelections"
          :organization.sync="organization"
        />
      </div>

      <tag-picker :tags.sync="tags" />

      <div class="alert alert-count form-control">
        <p
          ref="geoframeCountContainer"
          class="count"
        >
          0
        </p>
        <p>Geoframes Selected</p>
      </div>

      <div class="alert alert-count form-control">
        <p
          ref="deviceCountContainer"
          class="count"
        >
          0
        </p>
        <p>Total Devices</p>
      </div>

      <div class="form-control">
        <el-button
          :disabled="!formReady"
          :loading="busy"
          type="primary"
          @click="createAudience"
        >
          {{ mode === 'normal' ? 'Save Audience' : 'Save as New Audience' }}
        </el-button>
      </div>

      <div class="form-control">
        <el-button
          :disabled="busy"
          @click="resetForm"
        >
          Reset
        </el-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CountUp } from 'countup.js';
import TagPicker from '../global/TagPicker.vue';
import OrgPicker from '../global/OrgPicker.vue';
import moment from 'moment';
import { audience } from '@/adonis-api';
import DedupeControl from '../global/DedupeControl.vue';

export default {
  name: 'AudienceCreationForm',
  components: {
    DedupeControl,
    OrgPicker,
    TagPicker,
  },
  props: {
    mode: {
      validator(value) {
        return !value || ['normal', 'split'].includes(value);
      },
      default: 'normal',
    },
    selections: { type: Array, default: () => [] },
  },

  data() {
    return {
      busy: false,
      dedupe: this.$store.getters['user/autoDedupe'],
      organization: {},
      showDialog: '',
      title: null,
      tags: [],
    };
  },

  computed: {
    ...mapGetters('settings', ['rangeEndDate', 'rangeStart']),

    deviceCount() {
      return this.selections.reduce((acc, cur) => acc + cur.count, 0);
    },

    historicalSelections() {
      return this.selections.some(
        geoframe =>
          moment(geoframe.start).isBefore(
            this.rangeStart.clone().startOf('day'),
          ) && geoframe.historical,
      );
    },

    formReady() {
      if (
        !this.title ||
        this.selections.length === 0 ||
        (this.deviceCount === 0 && !this.historicalSelections)
      ) {
        return false;
      }

      return true;
    },
  },

  watch: {
    selections(newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        new CountUp(
          this.$refs.deviceCountContainer,
          newValue.reduce((acc, cur) => acc + cur.count, 0),
          {
            startVal: oldValue.reduce((acc, cur) => acc + cur.count, 0),
            duration: 1,
          },
        ).start();
        new CountUp(
          this.$refs.geoframeCountContainer,
          newValue.length,
          {
            startVal: oldValue.length,
            duration: 1,
          },
        ).start();
      }
    },
  },

  methods: {
    async createAudience() {
      this.busy = true;
      const params = {
        name: this.title.trim(),
        type: 'GEOFRAME',
        organization_id: this.organization.id,
        split: this.mode === 'split',
        tags: this.tags,
        historical: this.historicalSelections,
        geoframe_ids: this.selections.map(geo => geo.id),
        auto_dedupe: this.dedupe,
      };

      try {
        const response = await audience.create(params);

        window.mixpanel.track('Create Geoframe Audience', {
          ID: response.data.id,
          Name: response.data.name,
          Organization: this.organization.name,
          Geoframes: response.data.geo_count,
          Count: response.data.count,
          Historical: response.data.historical,
          'Start Date': response.data.start_date,
          'End Date': response.data.end_date,
        });
        this.$notify.success({
          message: 'Your audience has been saved.',
        });

        this.resetForm();
        this.$router.push('/audiences/library/list');
      } catch (e) {
        this.$notify.error({
          message: 'An error occurred while saving the audience. This issue has been reported.',
        });
        this.$reportError(e, {
          componentName: this.$options.name,
          params,
        });
      } finally {
        this.busy = false;
      }
    },

    resetForm() {
      this.organization = this.$store.state.auth.orgDetails;
      this.title = null;
      this.tags = [];
      this.$emit('reset');
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  margin-top: 4rem;
}

.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  .el-input,
  .el-select,
  .el-button {
    width: 100%;
  }
  .el-tag {
    background: #ddd;
    color: #333;
    margin: 0 5px 5px 0;
  }
}

.alert-count {
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  color: #ccc;
  border-color: #ddd;
  background-color: #eee;
  .count {
    margin: 0.5rem 0 0;
    font-size: 2rem;
    font-weight: 800;
    color: #bbb;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
  }
}
</style>
