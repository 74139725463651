<template>
  <div>
    <data-table
      ref="dataTable"
      :disable-action-menu="mode === 'normal'"
      :disable-search="mode === 'split'"
      :actions="dataTable.actions"
      :data-class="dataClass"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :find-options="{ ready: true }"
      :page-size="dataTable.pageSize"
      @action="handleAction"
      @selection-change="selections => $emit('selection-change', selections)"
    >
      <el-table-column
        prop="name"
        label="Geoframe"
        sortable="custom"
      />
      <el-table-column
        v-if="quickSelect && isInternal"
        label="Address"
      >
        <template slot-scope="{ row }">
          <el-button
            v-if="row.meta"
            v-clipboard:copy="row.meta ? row.meta.address : ''"
            v-clipboard:success="() => $message({ message: 'Address copied to clipboard.', type: 'success' })"
            icon="fa fa-lg fa-clipboard-list"
            plain
            size="mini"
            type="secondary"
            style="margin-right: 10px;"
          />
          {{ row.meta ? row.meta.address : '' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="count"
        label="Count"
        :width="110"
        sortable="custom"
      >
        <template slot-scope="data">
          <span v-if="data.row.historical">
            <el-tooltip
              class="item"
              effect="light"
              content="Historical Geoframe"
            >
              <i class="far fa-calendar-alt" />
            </el-tooltip>
          </span>
          <span v-else>
            {{ data.row.count | numeral }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="mode === 'normal'"
        prop="tags"
        label="Tags"
      >
        <template slot-scope="data">
          <el-tag
            v-for="tag in data.row.tags"
            :key="tag.id"
            size="mini"
          >
            {{ tag.name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="start_date"
        label="Start"
        sortable="custom"
        :width="110"
      />
      <el-table-column
        prop="end_date"
        label="End"
        sortable="custom"
        :width="110"
      />
      <el-table-column
        v-if="mode === 'normal'"
        prop="author"
        label="Author"
        :width="130"
        sortable="custom"
      >
        <template slot-scope="data">
          {{ data.row.author | fullName }}
        </template>
      </el-table-column>
      <el-table-column
        :width="110"
        prop="created_at"
        label="Created"
        sortable="custom"
      >
        <template slot-scope="data">
          {{ data.row.created_at | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="mode === 'normal'"
        prop="organization"
        label="Organization"
        sortable="custom"
        :width="150"
      >
        <template slot-scope="data">
          {{ data.row.organization.name }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="isInternal && audienceId > 0"
        :width="80"
      >
        <template slot-scope="data">
          <el-button @click="showOnMap(data.row.id)">
            Map
          </el-button>
        </template>
      </el-table-column>
    </data-table>
    <map-viewer :geoframe.sync="viewGeoframe" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { geoframe, audience as audienceApi, audienceGeodata } from '@/adonis-api';
import DataTable from '../global/DataTable.vue';
import MapViewer from '../global/MapViewer.vue';

export default {
  components: { DataTable, MapViewer },
  props: {
    audienceId: { type: Number, default: 0 },
    mode: {
      validator(value) {
        return !value || ['normal', 'split'].includes(value);
      },
      default: 'normal',
    },
    audienceType: { type: String, default: 'GEOFRAME' },
  },

  data() {
    return {
      dataTable: {
        actions: Object.freeze([]),
        order: 'descending',
        sortBy: 'created_at',
      },
      viewGeoframe: null,
    };
  },

  computed: {
    ...mapGetters('settings', ['rangeStart']),
    ...mapGetters('user', ['isInternal']),

    dataClass() {
      return this.mode === 'normal'
        ? this.geoframe
        : {
          find: options => {
            return audienceApi.getGeoframes(this.audienceId, options);
          },
        };
    },

    quickSelect() {
      return this.audienceType === 'POI';
    },
  },

  created() {
    this.geoframe = geoframe;
  },

  methods: {
    clearSelection() {
      this.$refs.dataTable.clearSelection();
    },

    handleAction() {}, // Needed for datatable component, or it'll cry. :( waaa!

    async showOnMap(id) {
      try {
        const response = await audienceGeodata.findOne(id, { geojson: 1 });

        this.viewGeoframe = {
          name: response.name,
          feature: response.geodata.geojson,
          googleLink: response.meta?.address ? `https://www.google.com/maps/search/?api=1&query=${response.meta.address.replace(/\s/g, '+')}` : null,
          geoframeCreateLink: response.meta?.point ? `/geoframes/create#18/${response.meta.point.lat}/${response.meta.point.lng}` : null,
          geodataId: response.geodata_id ? response.geodata_id : null,
        };
      } catch (e) {
        this.$reportError(e);
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: inline-block;
  margin: 16px 0;
}

.search-box {
  margin-bottom: 16px;
  max-width: 180px;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin-left: 4px;
}
</style>
